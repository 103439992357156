<template>
  <div class="detailreturns"> 
    <template>
      <v-container class="grey lighten-5">
          <v-row>
            <v-col>
              <h5>Buscar:</h5>
              <input type="text" placeholder="Escribe un producto" v-model="sword" v-on:keyup.13="getBreadcrumps(sword)">
            </v-col>
          </v-row>                                                                            
      </v-container>
    </template>

    <v-row>
      <v-col cols=12 sm=12 md=2 lg=2>
      <table>
        <tr><td style="text-align:left; font-size:1.1rem;">
          <!-- :value="'1234567890128'" -->
          <!-- :format="'ean13'" -->
          <!-- :format="'ITF14'" -->
          <!-- :format="'pharmacode'" -->
           <!-- {{ barfolio }} -->

           <!-- No lo renderiza al inicio -->
          <!-- <BarcodeGenerator
            :value="folio"
            :format="'CODE39'"
            :lineColor="'#000'"
            :width="2"
            :height="50"
            :elementTag="'img'"
        /> -->
          <img src="" id="barcodePDF" alt="" />
          <b>Folio: </b> {{ folio }}
        </td></tr>
        <tr><td style="text-align:left; font-size:1.1rem;"><b>Cliente: </b> {{ customername }}</td></tr>        
        <tr><td style="text-align:left; font-size:1.1rem;"><b>Fecha:</b> {{ date_quote }}</td>  </tr>        
      </table>
      </v-col>
    </v-row>
    <br><br>


    <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }"> 
                <v-btn
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-dialog
                    v-model="dialog2"
                    persistent
                    max-width="600px"                 
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="green"            
                        v-bind="attrs"
                        v-on="on"
                        plain
                      >
                        <v-icon color="white">edit</v-icon> 
                      </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                          <span class="headline">Editar Cliente: {{ customername }}</span>
                          <v-btn
                            class="headline"
                            color="blue darken-1"
                            text
                            @click="dialog2 = false"
                          >
                            <v-icon>close</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-container> 
                            <CustomerEdit :user="user" />
                            <!--
                              <CustomerForm :submitForm="DetailCustomer" :user="user" hasCustomer=true buttonText="Guardar"  /> 
                            -->                      
                          </v-container>
                          
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>          
                          <small style="color:red;">*Campos Obligatorios</small>
                          <!-- <v-btn 
                            color="blue darken-1"
                            text
                            v-on:click="submit"><v-icon>save</v-icon>
                          </v-btn>         -->
                        </v-card-actions>
                      </v-card>
                      </v-dialog>
                </v-btn>                
              </template>
              <span>Editar Cliente</span>
            </v-tooltip>
          </template>  

<!-- 
<v-container>
  <v-layout >
    <v-flex xs6 md6 lg6>

       <v-data-table
        :headers="theader"
        :items="desserts"
        :items-per-page="15"
        class="elevation-1"
      ></v-data-table>   
--> 

<!-- Dialog Confirmación -->

<!-- FIN Dialog confirmación -->
<v-card
    flat
    class="py-12"
  >
    <v-card-text >
      <v-row class="text-right" justify="center" >        
        <v-btn-toggle
          v-model="toggle_exclusive"
          mandatory
        >
        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <v-btn
                  @click="downloadPDF"
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>picture_as_pdf</v-icon>
                </v-btn>
              </template>
              <span>Descargar Cotización</span>
            </v-tooltip>
        </template>          
        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <v-btn
                  @click="orderByAsc"                  
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>format_list_numbered</v-icon>
                </v-btn>
              </template>
              <span>Ordenar la lista de productos</span>
            </v-tooltip>
        </template> 
        
        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }"> 
                <v-btn
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="600px"                 
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="green"            
                        v-bind="attrs"
                        v-on="on"
                        plain
                      >
                        <v-icon>add</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                          <span class="headline">Agregar Producto / Servicio</span>
                          <v-btn
                            class="headline"
                            color="blue darken-1"
                            text
                            @click="dialog = false"
                          >
                            <v-icon>close</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-container>            
                            <!--<ProductForm :submitForm="DetailProduct" hasQuote=true buttonText="Guardar"   />-->
                            <ProductQuoteCreate :user="user" />
                          </v-container>
                          
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>          
                          <small style="color:red;">*Campos Obligatorios</small>
                          <!-- <v-btn 
                            color="blue darken-1"
                            text
                            v-on:click="submit"><v-icon>save</v-icon>
                          </v-btn>         -->
                        </v-card-actions>
                      </v-card>
                      </v-dialog>
                </v-btn>                
              </template>
              <span>Agregar Producto</span>
            </v-tooltip>
        </template>   
        <!-- Dialog para agregar --> 

        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <v-btn
                  @click="copyCar(folio)"                   
                  color="darken-2"               
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>add_shopping_cart</v-icon>
                </v-btn>
              </template>
              <span>Agregar al Carrito</span>
            </v-tooltip>
        </template>
                  
        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <v-btn
                  @click="$router.back()" 
                  color="primary darken-2"               
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                <v-icon>arrow_back</v-icon>
                </v-btn>
              </template>
              <span>Regresar a la Página Anterior</span>
            </v-tooltip>
        </template>
          
        
      </v-btn-toggle>
      </v-row>
    </v-card-text>
  </v-card>
  
  
  <!-- Lado Izquierdo -->
  <v-row v-if="rows.length > 0">
    <v-col cols="12" xs="12" sm="12" md="8" lg="10" xl="10">  
      <table class="table table-striped">
        <thead class="thead-dark">
          <tr><th v-for="thead in theader" :key="thead.id">{{thead.text}}</th></tr>               
        </thead>     
        <tr v-for="(row) of rows" :key="row.id">       
          <td>          
            
            <v-img
            :src="row.productfilename"
            :lazy-src="row.productfilename"
            :alt="`${row.productname} avatar`"
            aspect-ratio="1"
            class="grey lighten-2"
            max-height="95"
            max-width="95"
            ></v-img>            
            
            <br><br>
          <template>  
                      <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">              
                              <v-btn
                                color="red"
                                x-small
                                fab
                                @click="deleteRow(row.id)"
                                v-bind="attrs"
                                v-on="on"                                
                              >
                                <v-icon>delete</v-icon>
                              </v-btn>
                            </template>
                            <span>Borrar Producto</span>
                          </v-tooltip>
          </template> 

          <template> 
            <v-tooltip top>
              <template v-slot:activator="{ on }">   
                <v-btn
                v-if='row.url != null'
                                  color="deep-white default"
                                  x-small
                                  fab
                                  v-bind:href="row.url"
                                  target="_blank"
                                  v-on="on"
                                  >
                                  <v-icon>public</v-icon>                  
                                </v-btn>            
                            </template>
                            <span>Ir Sitio Web</span>
                          </v-tooltip>
                      </template>                                                           
                      
                      <template>  
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">              
                              <v-btn
                                color="secondary"
                                x-small
                                fab
                                @click="editRow = row.id"
                                v-bind="attrs"
                                v-on="on"                                
                                >
                                <v-icon>edit</v-icon>
                              </v-btn>
                            </template>
                            <span>Editar Producto</span>
                          </v-tooltip>
                        </template>                              
                        <template> 
                                        <v-tooltip top>
                                          <template v-slot:activator="{ on }">   
                                            <v-btn
                                                color="blue"
                                                x-small
                                                fab
                                                v-on:click="getProduct(row.pricelist_id)"                                  
                                                v-on="on"
                                            >
                                                <v-icon>visibility</v-icon>                  
                                            </v-btn>            
                                          </template>
                                          <span>Detalle Producto</span>
                                        </v-tooltip>
                        </template> 

        </td> 
        <td>
          <a :href="row.url" target="_blank">{{row.productname}}</a>
          <v-btn
                            class="headline"
                            color="blue darken-1"
                            text
                            @click="getDataProduct(row)"
                          >
                             <v-icon>edit</v-icon>
                          </v-btn>
                          <br>
          <BarcodeGenerator
              :value="row.pricelist_id"
              :format="'CODE39'"
              :lineColor="'#000'"
              :width="2"
              :height="50"
              :elementTag="'img'"
              :displayValue=false
          /> 
          <br>
            
            
            <br>                 
            <small><b>ID:</b> {{row.id}}</small> | <small> <b>Producto ID:</b> {{row.productid}}</small> | <small><b>SKU:</b> {{row.productsku}}</small> <br>    
            <small><b>Marca:</b> {{row.brandname}}</small> | <small><b>Modelo:</b> {{row.productmodelo}}</small> <br>                      
            <small><b>Clave SAT:</b> {{row.productclavesat}}</small><br>                                            
            Fecha Autorizacion: {{row.authorized}} <br> 
            Fecha Creación: {{row.created_at}} <br> 
            Fecha Actualización: {{row.updated_at}} <br> <br>
            <div v-if="editRow === row.id">
            <!-- <input v-on:keyup.13="updateRow(row)" placeholder="Cotización ID" v-model="row.quote_id" /> -->
            <v-autocomplete
              v-model="row.pricelist_id"
              :items="pricelists"
              item-text="description"
              item-value="id"
              label="Selecciona un producto*"
              outlined
            ></v-autocomplete>                                                
            <v-text-field
                        v-model="row.posicion"
                        v-on:keyup.13="updateRow(row)"
                        label="Posición"
            />  
            <v-text-field
                        v-model="row.price"
                        readonly
                        v-on:keyup.13="updateRow(row)"
                        label="Precio"
            />  
            <v-text-field
                        v-model="row.cantidad"
                        v-on:keyup.13="updateRow(row)"
                        label="Cantidad"
            /> 
            <v-text-field
                        v-model="row.discount"
                        readonly
                        v-on:keyup.13="updateRow(row)"
                        label="Descuento"
            /> 
            <v-row>
              <v-col cols=12>
                <v-textarea
                            v-model="row.notes"
                            filled
                            auto-grow
                            label="Escribe comentarios"
                            rows="4"
                            row-height="30"
                            shaped
                ></v-textarea>    
              </v-col>
            </v-row>        
            <v-btn v-on:click="updateRow(row)" class="green">Actualizar</v-btn>
            <v-btn v-on:click="editRow=false" class="default">Cerrar</v-btn>
          </div>
          <div v-else>  </div>  
        </td>
        <td>
          <b>Precio de Lista </b>  <br> {{row.price | currency }} <br>  
          <b>Descuento </b> <br> {{(row.price-(row.price*(row.discount/100))) | currency }}  <small>({{row.discount}}%)</small>
        </td>        
        <td>
          {{row.cantidad}}
        </td>
        <td>
          {{ calculateLineTotal(row) | currency }}        
        </td>
        <td>
          <div v-if="row.notes != null && row.notes != ''">   
            <v-btn
                  color="red lighten-2"
                  dark
                  @click="getNote(row.notes)" 
                >
                  ver nota
            </v-btn>
          
          </div>
        </td>
        <td></td>        
        <td></td>      
      </tr>
    </table>     
    
    <v-row>
      <v-col cols=12 sm=12 md=12 lg=12>   
        <table>
          <!-- <tr>
            <td style="text-align:left; font-size:1.1rem;"></td> 
            <td style="text-align:right; font-size:1.1rem;"><b>Subtotal:</b> {{ quote_subtotal | currency }}</td> 
          </tr>
          <tr>
            <td style="text-align:left; font-size:1.1rem;"></td> 
            <td style="text-align:right; font-size:1.1rem;"><b>Desc:</b> {{ desc | currency }}</td>  
          </tr>
          <tr>
            <td style="text-align:left; font-size:1.1rem;"></td> 
            <td style="text-align:right; font-size:1.1rem;"><b>Subtotal c/Desc:</b> {{ quote_subtotal2 | currency }}</td>  
          </tr>
          <tr>
            <td style="text-align:left; font-size:1.1rem;"></td> 
            <td style="text-align:right; font-size:1.1rem;"><b>IVA:</b> {{ iva | currency }}</td>  
          </tr> -->
          <tr>
            <td style="text-align:left; font-size:1.1rem;"></td> 
            <td style="text-align:right; font-size:1.1rem;"><b>Total:</b> {{ quote_total | currency }}</td> 
          </tr>
        </table>
      </v-col>
    </v-row>      
  </v-col>
  <!-- Lado Derecho -->
  <v-col cols="12" xs="12" sm="12" md="4" lg="2" xl="2">    
    <h5 >Ordenar Lista {{ draggingInfo }}</h5>
    <div class="form-check">
          <input
            id="disabled"
            type="checkbox"
            v-model="enabled"
            class="form-check-input"
          />
          <label class="form-check-label" for="disabled">Habilitar</label>
    </div>
    <br><br>
      <v-card
        class="mx-auto"
        max-width="100%"
      >
        <v-list>
          <v-list-item-group       
            mandatory     
            color="indigo"
          >
              <draggable
                :list="rows"
                :disabled="!enabled"
                class="list-group"
                ghost-class="ghost"
                :move="checkMove"
                @start="dragging=false"
                @end="order2()"
              >
                <v-list-item
                  v-for="(row,index) in rows"
                  :key="row.id"                  
                  v-slot="{ active, toggle }"
                >
                  <v-list-item-avatar>
                    <v-img
                        :src="row.productfilename"
                        :lazy-src="row.productfilename"
                        :alt="`${row.productname} avatar`"
                        aspect-ratio="1"
                        class="grey lighten-2"
                        max-height="95"
                        max-width="95"
                    ></v-img>                    
                  </v-list-item-avatar>
                  <v-list-item-content
                    :color="active ? 'primary' : ''"
                    @click="toggle"
                  >                    
                    <v-list-item-title>
                      <a :href="row.url" target="_blank">{{row.productname}}</a>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <small><b>Marca:</b> {{row.brandname}}</small> | <small><b>Modelo:</b> {{row.productmodelo}}</small> <br>                      
                      <small><b>SKU:</b> {{row.productsku}}</small><br>
                      <small><b>ID:</b> {{row.id}}</small> | <small> <b>Producto ID:</b> {{row.productid}}</small><br>
                      Index: {{index+1}} -  Posicion {{row.posicion}}
                    </v-list-item-subtitle>                    
                  </v-list-item-content>                   
                </v-list-item>
              </draggable>
          </v-list-item-group>
        </v-list>     
      </v-card>  
    </v-col>
    <!-- FIN LADO DERECHO -->
  </v-row>
  <v-row v-else>
    <v-col cols=12 sm=12 md=12 lg=12 >
    <v-alert
            dense
            outlined
            type="error"
          >
            No hay registros encontrados con esta busqueda
      </v-alert>
    </v-col>
  </v-row>
  

<!-- VISTA PARA EDITAR PRODUCTO -->
<template>                 
                      <v-dialog
                        v-model="dialog10"
                        persistent
                        max-width="800px"                 
                      >
                     
                          <v-card>
                            <v-card-title>
                              <span class="headline">Editar Producto </span>
                              <v-btn
                                class="headline"
                                color="blue darken-1"
                                text
                                @click="dialog10 = false"
                              >
                                <v-icon>close</v-icon>
                              </v-btn>
                            </v-card-title>
                            <v-card-text>
                              <v-container v-if='this.dialog10==true'>
                                <ProductEdit :product="product" />                                
                              </v-container>
                          </v-card-text>
                          <v-card-actions>
                              <v-btn 
                                color="red" 
                                @click="dialog10 = false"
                              >
                                cerrar
                              </v-btn>
                              <v-spacer></v-spacer>   
                              <small style="color:red;">*Campos Obligatorios</small>
                            </v-card-actions>
                          </v-card>                    
                      </v-dialog>
              </template>    
<!-- FIN VISTA PARA EDITAR PRODUCTO -->
<!-- VISTA PRODUCTO DIALOG-->
<template>                 
                      <v-dialog
                        v-model="dialogViewProduct"
                        persistent
                        max-width="800px"                 
                      >
                          <v-card>
                            <v-card-title>
                              <span class="headline">Detalle Producto</span>
                              <v-btn
                                class="headline"
                                color="blue darken-1"
                                text
                                @click="dialogViewProduct = false"
                              >
                                <v-icon>close</v-icon>
                              </v-btn>
                            </v-card-title>
                            <v-container v-if='this.dialogViewProduct==true'>
                                <ProductView :productid="productid"  />
                            </v-container>
                            <!-- :pricelist="priceslist -->
                            <!-- buttonText="Guardar"   -->
                          <v-card-actions>
                              <v-btn 
                              
                              color="red" 
                              @click="dialogViewProduct = false"
                              >
                              cerrar
                            </v-btn>
                              <v-spacer></v-spacer>              
                            </v-card-actions>
                          </v-card>                    
                      </v-dialog>
</template>                 
<!-- FIN VISTA PRODUCTO  DIALOG-->
      
      
      <!-- DIALOG NOTAS -->
   <div class="text-center">
            <v-dialog
              v-model="dialogNotes"
              width="30%"
            >
              <v-card>
                <v-card-title class="headline grey lighten-2">
                  Notas
                </v-card-title>
                <v-card-text class="my-5">
                  {{notes}}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="dialogNotes = false"
                  >
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
    </div>
    <!-- FIN DIALOG NOTAS -->
      
   
    <!--    
    </v-flex>
  </v-layout>
</v-container>
 -->
  </div>
</template>


<script>
// import axios from "axios";
import draggable from "vuedraggable";
import{ mapMutations,mapState } from "vuex";
//import FieldSearch from '@/components/FieldSearch.vue'
import jsPDF from 'jspdf' 
import JsBarcode from 'jsbarcode';

import domtoimage from "dom-to-image";
//import DetailQuoteAdd from "@/components/DetailQuoteAdd.vue";
import baseApi from '@/services/baseApi';
import ProductQuoteCreate from "@/components/ProductQuoteCreate";
import CustomerEdit from "@/components/CustomerEdit";
import ProductEdit from "@/components/ProductEdit";
import ProductView from "@/components/ProductView";
import BarcodeGenerator from "@/components/BarcodeGenerator";



export default {
  name: 'detailreturns',
  index:1,
  order: 1,
  computed:{
    ...mapState(['currentUser']),
    draggingInfo() {
      return this.dragging ? "...arrastrando" : "";
    }    
  },  
  components: {    
    //DetailQuoteAdd,
    //FieldSearch,
    draggable,
    ProductQuoteCreate,
    ProductEdit,
    ProductView,
    CustomerEdit,
    BarcodeGenerator
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),    
    checkMove: function(e) {      
      window.console.log("Nombre: " + e.draggedContext.element.productname);
      window.console.log("Future index: " + e.draggedContext.futureIndex);
      window.console.log("Origen index: " + e.draggedContext.index);
      window.console.log("destino index: " + e.relatedContext.index);  
      this.index=e.draggedContext.index;
      this.order=e.relatedContext.index;      
      window.console.log("Index: " + this.index);
      window.console.log("Orden: " + this.order);      
    },
    orderByAsc(){
      let i=0;      
      let size=this.rows.length;      
      for (i; i < size; i++) { 
            this.rows[i].posicion = i+1;                       
            this.updateRow(this.rows[i]); //Funciona pero pasa todos los valores
            window.console.log("Posicion: " + this.rows[i].posicion);
      }         
      
    },
    order2(){
      this.dragging=false;
      let i=0;
      let id=this.index;      
      let order=this.order;
      let size=this.rows.length;

      // alert("Por fin lo solto");
      // console.log("ID: "+id)
      // console.log("Size: "+this.list.length)            

      if (order>id){        
          for (i = id; i < size; i++) { 
            this.rows[i].posicion = i+1;                       
          }          
      }else{
        for (i = order; i < size; i+=1) { 
          this.rows[i].posicion = i+1;                       
        }
      }
      
    },
    calculateLineTotal(row) {            
            var stotal = parseFloat(row.price) * parseFloat(row.cantidad);
            var total = parseFloat(stotal-(stotal*(row.discount/100)));            
            
            if (!isNaN(total)) {
                //row.line_total = total.toFixed(2);
                //total.toFixed(2);                
                //return total.toFixed(2);
                return total;
                
            }
            //return  total;
            //this.calculateTotal(total);
    },
    getDataProduct(row) {       
          this.product=row
          this.dialog10=true
    },
    updateRow(row) {      
      try{        
          this.showLoading({titulo:"Accediendo información",color:"warning"});          
          baseApi().post(`/detalledevolucion/update/${row.id}`,JSON.stringify(row)).then((response)=>{
            if(!response.data.error){                            
              this.$swal("Genial!",response.data.message, "success");
            }else{
              this.$swal("Error!",response.data.message, "error");
            }                                    
            console.log("COTIZACION: "+response.data.message);
          });
        }catch(error){
          console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", "Se actualizó la información", "success");
        } 
    },
    deleteRow(id) {      
      // alert(id);
      try{        
          this.showLoading({titulo:"Accediendo información",color:"warning"});
          
          if(confirm("Desea borrar definitivamente el registro: "+ id+"?")){
            // alert("Estoy en OK")
            baseApi().get(`/detalledevolucion/delete/${id}`).then((response)=>{
              if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
              console.log("DETALLE DEVOLUCION: "+response.data.message);
            });
          }else{
            // alert("NO acepto :(")
          }          
        }catch(error){
          console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", "Se actualizó la información", "success");
        } 
    },
    getNote(note){
      // console.log('Producto ID:'+ProductID);
      this.notes=note;
      // alert(this.productid);
      this.dialogNotes =true;  
    },  
    async getProduct(ProductID){
        console.log('Producto ID:'+ProductID);
        this.productid=ProductID;
        // alert(this.productid);
        this.dialogViewProduct=true;      
      },
    // async getProduct(ProductID){
    //   this.dialog12=true;
    //   console.log('Producto ID:'+ProductID);
    //   baseApi().get(`/precios/${ProductID}`).then((response)=>{
    //           if(!response.data.error){                            
    //             // this.$swal("Genial!",response.data.message, "success");
    //             this.productrow=response.data
    //             // this.rows.productfilename=response.data.filename
    //             //alert(this.rows.productfilename)

    //           }else{
    //             this.$swal("Error!",response.data.message, "error");
    //           }                                    
    //   });

      
    // }, 
    downloadPDF(){
        // this.getBarcode(this.folio) //ya no necesario por que lo cargo antes con getBreadcrumps
        //const doc = new jsPDF('p', 'mm', [297, 210]); 
        const doc = new jsPDF('l', 'mm', [297, 210]);    
        doc.setProperties({
            title: 'Cotización',
            subject: 'Cotización',
            author: 'iOnOff Automatización de Casas',
            keywords: 'ionoff, smart home',
            creator: 'Emmanuel Carreón'
        });       
        var folio="QO"+this.rows[0].quote_id;
        doc.page = 1; // use this as a counter.
        var totalPages = 0; // define total amount of pages
        var leyenda="";
        //var leyenda="*** Si se contrata en los primeros siete días de la fecha de emisión se hace un descuento del 3% ***";
        // HEADER
        doc.setFontSize(16);//optional
        // doc.setTextColor(40);//optional
        doc.setFont(undefined, "bold");
        doc.text("COTIZACIÓN NORMAL ",30, 18);// set your margins
        doc.setFontSize(10);
        doc.setFont(undefined, "normal");
        doc.text(this.rows[0].title,30, 23);// set your margins
        // FIN FOOTER

        //var imgData = 'data:image/jpeg;base64,verylongbase64;'
        //var imgData = 'https://www.ionoff.com.mx/wp-content/uploads/2019/07/iOnOff_logo_100x100.png';
        //var imgData ='https://pbs.twimg.com/profile_images/996822127700406272/hNY5DZw-_normal.jpg';
        //iOnOffMX
        // var imgData ='https://lh3.googleusercontent.com/ogw/ADGmqu_nde3VWYvJC7lGnEWQ1s7UkYMpyVLGkcERiiiY=s64-c-mo';
        //CloudBits
        // var imgData ='https://lh3.googleusercontent.com/ogw/AAEL6sgnn_GcMf9FB9izmKp3INhEmGHe9Mwu814PKVXB=s32-c-mo';
        var imgData =this.currentUser.branch_filename;
        doc.addImage(imgData, 'JPEG', 9, 10, 15, 15);


        //console.log(imgData); 
        
        //doc.text(15, 15, reportTitle);
        //doc.addImage(outputURL, 'JPEG', 15, 40, 180, 100);        
        //var miArray = [ 2, 4, 6, 8, 10 ];
        //console.log(this.rows.length);
        //console.log(this.rows);
        //console.log(this.rows[1].id);        

        doc.setFontSize(9);
        // <!-- <img src="" id="barcode" alt="" /> -->
        // const img=document.querySelector('img');
        const img=document.querySelector('#barcodePDF');
        doc.addImage(img,210,3,65,15);
        doc.text("Folio: "+folio,227.7,21);
        doc.text("Fecha: "+this.rows[0].created_at,226,27);
        doc.text("Cliente: "+this.rows[0].customerfullname,225,33);

                
        var yh=45;
        doc.setFontSize(8);
        //doc.setFontType("bold"); No funciona
        // this.setFont("helvetica", "bold");
        doc.setFont(undefined, "bold");
        doc.text("CANT.",7,yh);
        doc.text("DESCRIPCIÓN",17,yh);        
        doc.text("PRECIO DE\rLISTA",205,yh,'center');
        doc.text("DESC.",225,yh,'center')        
        doc.text("PRECIO\rUNITARIO",240,yh,'center')        
        doc.text("IMPORTE",255,yh);
        doc.setFont(undefined, "normal");        

        
        var y=55;   
        var total=0;    
        var stotal=0;   
        var desc=0;   
        var stotal2=0;   
        var iva=0;            
        var gtotal=0;
        var priceuni=0;
        var str = "";        
        doc.setFontSize(7);
        
        // console.log("Error LOG= "+this.rows.length);        
        for (var i = 0; i < this.rows.length; i+=1) { 
          // consecutivo=i+1;
          priceuni=this.rows[i].price-(this.rows[i].price*(this.rows[i].discount/100));
          // console.log("Alto= "+y);          
          if (y>=162){
            yh=24;
            y=33;
            totalPages+=1;            
            // FOOTER            
            str = "Página " + doc.page  + " de " +  totalPages;
            
            doc.setFontSize(7);// optional            
            doc.text(str, 15, doc.internal.pageSize.height - 10);//key is the interal pageSize function          
            doc.setTextColor(0,85,136);
            doc.text(leyenda, 43, doc.internal.pageSize.height - 10);//key is the interal pageSize function
            doc.setTextColor(0,0,0);
            //iOnOffMx
            // doc.text("www.ionoff.com.mx  |  (222)207.11.06  |  ventas@ionoff.com.mx", 215, doc.internal.pageSize.height - 10);//key is the interal pageSize function                  
            // CloudBits            
            // doc.text("www.cloudbits.org.mx  |  (222)533.49.15  |  escarreon@cloudbits.org.mx", 205, doc.internal.pageSize.height - 10);//key is the interal pageSize function                  
            doc.text(this.currentUser.branch_site+"  |  "+this.currentUser.branch_mobile+"  |  "+this.currentUser.branch_email, 205, doc.internal.pageSize.height - 10);

            doc.addPage();            
            doc.setFontSize(8);
            //doc.setFontType("bold"); No funciona
            // this.setFont("helvetica", "bold");
            doc.setFont(undefined, "bold");
            doc.text("CANT.",7,yh);
            doc.text("DESCRIPCIÓN",17,yh);        
            doc.text("PRECIO DE\rLISTA",205,yh,'center');
            doc.text("DESC.",225,yh,'center')        
            doc.text("PRECIO\rUNITARIO",240,yh,'center')        
            doc.text("IMPORTE",255,yh);
            doc.setFont(undefined, "normal");
            doc.setFontSize(7);            
          }
          
          total=0;    
          //total=this.rows[i].cantidad*this.rows[i].price;
          total=this.calculateLineTotal(this.rows[i]);
          stotal+=total;
          //console.log("En el índice '" + i + "' hay este valor: " + this.rows[i].id);
          //console.log("total: " + total);
          // console.log("Producto= "+this.rows[i].productsku.toString());
          // doc.text(consecutivo.toString(),10,y);
          doc.text(this.rows[i].cantidad.toString(),10,y,'center');          
          if ( this.rows[i].url ==  null){ 
            if ( this.rows[i].notes !=  null)
              doc.text(this.rows[i].productname.toString()+"\r"+this.rows[i].notes.toString()+"\rSKU: "+this.rows[i].productsku.toString()+"  |  Marca: "+this.rows[i].brandname.toString()+"  |  Modelo: "+this.rows[i].productskufab.toString(),17,y);
            else
              doc.text(this.rows[i].productname.toString()+"\rSKU: "+this.rows[i].productsku.toString()+"  |  Marca: "+this.rows[i].brandname.toString()+"  |  Modelo: "+this.rows[i].productskufab.toString(),17,y);
          }else{
            if ( this.rows[i].notes !=  null)
              doc.textWithLink(this.rows[i].productname.toString()+"\r"+this.rows[i].notes.toString()+"\rSKU: "+this.rows[i].productsku.toString()+"  |  Marca: "+this.rows[i].brandname.toString()+"  |  Modelo: "+this.rows[i].productskufab.toString(), 17, y, {url: this.rows[i].url.toString()});          
            else
              doc.textWithLink(this.rows[i].productname.toString()+"\rSKU: "+this.rows[i].productsku.toString()+"  |  Marca: "+this.rows[i].brandname.toString()+"  |  Modelo: "+this.rows[i].productskufab.toString(), 17, y, {url: this.rows[i].url.toString()});          
          }
          doc.text("$"+this.rows[i].price.toString().replace(/\d(?=(\d{3})+\.)/g, '$&,'),205,y,'center');          
          doc.text("-"+this.rows[i].discount.toString()+"%",225,y,'center');
          doc.text("$"+priceuni.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),240,y,'center');
          doc.text("$"+total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),255,y);
          //doc.text(String(total | currency).toFixed(2),245,y);
          y = y + 12;          
        }        
        
        if (this.rows[0].quotediscount!=0) desc=stotal*(this.rows[0].quotediscount/100);
        else desc=0;

        stotal2=stotal-desc;
        

        if (this.rows[0].iva!=0) iva=stotal2*0.16;
        else iva=0;
        
        gtotal=stotal2+iva;        

        y = y+6;  
        doc.setFontSize(8);      
        doc.text("SUBTOTAL:",245,y,'right'); 
        doc.text("$"+stotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),268,y,'right');         
        y = y+6;   
        if (desc>=1){
          doc.text("DESCUENTO:",245,y,'right'); 
          doc.text("$"+desc.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),268,y,'right'); 
          y = y+6;        
          doc.text("SUBTOTAL c/DESC:",245,y,'right'); 
          doc.text("$"+stotal2.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),268,y,'right');             
          y = y+6;          
        }              
        doc.text("IVA:",245,y,'right'); 
        doc.text("$"+iva.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),268,y,'right');                                     
        y = y+6;        
        doc.setFontSize(10);
        doc.text("TOTAL:",245,y,'right'); 
        doc.text("$"+gtotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),268,y,'right');        

        //Nota
        if (this.rows[0].notes!=""){
          y = y+30;                
          doc.setFontSize(8);        
          doc.setTextColor(0,0,0);          
          //doc.text("NOTA:",10,y);   
          y = y+5;                   
          doc.setTextColor(40);          
          //doc.text(this.rows[0].notes,10,y);  // Se confunde con las notas de la tabla quotes con las de la tabla detailreturns
        }        



        // FOOTER   
        totalPages+=1;             
        str = "Página " + doc.page  + " de " +  totalPages;        
        doc.setFontSize(7);// optional          
        // doc.setFont("helvetica");
        // doc.setFontType("bold");
        doc.text(str, 15, doc.internal.pageSize.height - 10);//key is the interal pageSize function          
        doc.setTextColor(0,85,136);
        doc.text(leyenda, 43, doc.internal.pageSize.height - 10);//key is the interal pageSize function
        doc.setTextColor(0,0,0);
        //iOnOffMx
        // doc.text("www.ionoff.com.mx  |  (222)207.11.06  |  ventas@ionoff.com.mx", 215, doc.internal.pageSize.height - 10);//key is the interal pageSize function
        //CloudBits
        //doc.text("www.cloudbits.org.mx  |  (222)533.49.15  |  escarreon@cloudbits.org.mx", 205, doc.internal.pageSize.height - 10);//key is the interal pageSize function
        doc.text(this.currentUser.branch_site+"  |  "+this.currentUser.branch_mobile+"  |  "+this.currentUser.branch_email, 205, doc.internal.pageSize.height - 10);

        //doc.text(this.name,15,15);
        // doc.output('dataurlnewwindow', {filename: folio+".pdf"});
        doc.save(folio+".pdf")
    },
    downloadWithCSS() {
      /** WITH CSS */
      domtoimage
      .toPng(this.$refs.content)
      .then(function(dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        const doc = new jsPDF({
          orientation: "portrait",
          // unit: "pt",
          format: [900, 1400]
        });
        doc.addImage(img, "JPEG", 20, 20);
        const date = new Date();
        const filename =
          "timechart_" +
          date.getFullYear() +
          ("0" + (date.getMonth() + 1)).slice(-2) +
          ("0" + date.getDate()).slice(-2) +
          ("0" + date.getHours()).slice(-2) +
          ("0" + date.getMinutes()).slice(-2) +
          ("0" + date.getSeconds()).slice(-2) +
          ".pdf";
        doc.save(filename);
      })
      .catch(function(error) {
        console.error("oops, something went wrong!", error);
      });
    },  
    async getBreadcrumps(sword){
      this.changeBreadcrumbs({
        page:"Detalle Devolucion "+sword,
        title:"Lista de detalle Devolucion",
        subtitle:"primary",
        description:"En esta lista encontrará todo el detalle de la devolución dadas de alta en el sistema desde el principio de los tiempos"
       });
       let branch_id=this.currentUser.branch_id;
      //let branch_filename=this.currentUser.branch_filename;
      //let aFecha=dia.split(["-"])
      //let ddmmyy=aFecha[2]+"-"+aFecha[1]+"-"+aFecha[0];

      try{            
        this.showLoading({titulo:"Accediendo información",color:"primary"})        
        let datos=await baseApi().get(`/detalledevolucion/`+this.$route.query.returnid+`?branch_id=`+branch_id);
        //console.log(datos);
        
        if(datos.data.length >0){
          this.rows=datos.data;    
          // console.log(this.rows);
          for (var i = 0; i < this.rows.length; i+=1) {               
            this.barfolio="'"+this.rows[0].quote_id+"'";
            this.folio=this.rows[0].quote_id;
            this.getBarcode(this.folio);
            this.customername=this.rows[0].customerfullname;
            this.date_quote=this.rows[0].date_quote; 
            //let total_line=this.rows[i].price*this.rows[i].cantidad;            
            let total_line=this.calculateLineTotal(this.rows[i]);
            //this.quote_subtotal=(this.quote_subtotal + (total_line-(total_line*(this.rows[i].discount/100))));  //Se multiplica con uno para que lo convierta en numero                        
            this.quote_subtotal=this.quote_subtotal + total_line;

            // if (this.rows[0].quotediscount!=0) this.desc=(this.quote_subtotal*(this.rows[0].quotediscount/100)); 
            // else this.desc=0;

            // this.quote_subtotal2=this.quote_subtotal-this.desc;

            
            // if (this.rows[0].iva!=0) this.iva=(this.quote_subtotal2*0.16); 
            // else this.iva=0;
            // this.quote_total=this.quote_subtotal2 + this.iva;  //Se multiplica con uno para que lo convierta en numero
            this.quote_total=this.quote_subtotal;  //Se multiplica con uno para que lo convierta en numero
            this.quote_id=this.rows[i].quote_id; 
          }           
          //this.desserts=datos.data          
        }else{
          this.rows=[];
        }
      }catch(error){
        //console.log(error);
      }finally{
        this.hiddenLoading()
      } 

      try{       
        this.showLoading({titulo:"Accediendo información",color:"primary"})        
        let datos=await baseApi().get(`/precios/getall?branch_id=`+branch_id);
        //console.log(datos);
        if(datos.data.length >0){
          this.pricelists=datos.data
        }else{
          this.pricelists="Sin resultados";
        }
      }catch(error){
        //console.log(error);
      }finally{
        this.hiddenLoading()
      }

      //Obtenemos Datos del Cliente
          // try{        
          //   this.showLoading({titulo:"Accediendo información del Cliente",color:"warning"})
          //   baseApi().get(`clientes/${this.rows[0].customer_id}`).then((response)=>{
          //     if(!response.data.error){
          //       this.user=response.data;                             
          //       //this.$swal("Genial!",response.data.message, "success");
          //     }else{
          //       this.$swal("Error!",response.data.message, "error");
          //     }                                    
          //   });
          // }catch(error){
          //   console.log(error);
          // }finally{
          //   this.hiddenLoading()
          // } 
    },
   
    getBarcode(code){
        // JsBarcode('#barcodePDF',code);   
        JsBarcode("#barcodePDF", code, {
            // format: "pharmacode",
            // lineColor: "#0aa",
            width: 2,
            height: 40,
            displayValue: false
          });

    }
    // submit: async function() {
    //   try{ 
    //     this.showLoading({titulo:"Almacenando información",color:"warning"})
    //     const { quote_id,pricelist_id,price,cantidad } = this;
    //     //let datos=await baseApi().post();
    //     baseApi().post(`/detallecotizacion`,JSON.stringify({ quote_id,pricelist_id,price,cantidad })).then((response)=>{
    //         if(!response.data.error){                            
    //           this.$swal("Genial!",response.data.message, "success");
    //         }else{
    //           this.$swal("Error!",response.data.message, "error");
    //         }                                    
    //         console.log("COTIZACION: "+response.data.message);
    //     });
    //   }catch(error){
    //     //console.log(error);
    //   }  finally{
    //     this.hiddenLoading()
    //     // this.$swal("Genial!", "Se agregó la información", "success");
    //   }      
    // },
  },
  data(){
    return{
      skus: [
      {title: 'OMS Order Number', value: '000000703'},
      {format: 'ean13', title: 'Honda Generator', value: 786102004732},
      {format: 'upc', title: 'Jack Oil', value: 44871190958}
    ],
      enabled: false, 
      dialogViewProduct:false,   
      dragging: false,
      dialog: false,
      dialog2: false,
      dialog10: false,
      dialog12: false,
      dialogNotes:false,
      notes:'',
      toggle_exclusive:'',
      sword:"",
      j:1,
      productrow:{},
      product:{},
      folio:"",
      customername:"",
      date_quote:"",
      desc:0,
      sfecha:new Date().toISOString().substr(0,10),
      user: [],
      rows: [],
      editRow:null,
      quote_id:'',
      pricelists:[],      
      quote_subtotal: 0,
      quote_subtotal_desc:0,
      quote_total: 0,
      quote_tax: 16,      
      quote_iva: 0,      
      theader: [
        { text: 'Acción', value: 'num' },        
        { text: 'Descripción', value: 'desc' },
        { text: 'Precio de Lista', value: 'precio_other' },
        { text: 'Cantidad', value: 'cantidad' },                
        { text: 'Total', value: 'total' },        
      ],
      acciones: [
        { title: 'Download PDF',icon:"save",metodo:"downloadPDF" }
      ],      
    }
  },
  beforeCreate(){
    
  },
  activated(){
    
  },
  mounted(){
    // JsBarcode('#barcode','this.folio');    
    
  },
  created(){
    this.getBreadcrumps(this.sword)
  }
}
</script>