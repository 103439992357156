<template>
  <div class="productsaleOrder"> 
    <template>
      <v-container class="grey lighten-5">
          <v-row>
            <v-col cols=12 xs=12 sm=12  md=4  lg=4  xl=4 >
              <h5>Buscar:</h5>
              <input type="text" placeholder="Escribe un producto" v-model="sword" v-on:keyup.13="getBreadcrumps(sword)">
            </v-col>
          </v-row>                                                                            
      </v-container>
    </template>

  <div>
      <table>
        <tr><td style="text-align:left; font-size:1.1rem;"><b>Sucursal: </b> {{ branchname }}</td></tr>
        <tr><td style="text-align:left; font-size:1.1rem;"><b>Producto: </b> {{ productname }}</td></tr>
      </table>
  </div>
  <br><br>

<!-- Filtros -->
  <!-- Menu -->
    <template>
                      <!-- FIN Dialog confirmación -->
                      <v-card flat  class="py-1" >
                            <v-card-text >
                              <v-row class="text-right" justify="center" >        
                                <v-btn-toggle
                                  v-model="toggle_exclusive"
                                  mandatory
                                >
                                <template>  
                                    <v-tooltip top>
                                      <template v-slot:activator="{ on, attrs }">              
                                        <v-btn
                                          @click="downloadExcel"
                                          color="green"
                                          v-bind="attrs"
                                          v-on="on"
                                        >                
                                          <v-icon>file_present</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Descargar Lista de Precios con Stock</span>
                                    </v-tooltip>
                                </template>          
                                          
                                <template>  
                                    <v-tooltip top>
                                      <template v-slot:activator="{ on, attrs }">              
                                        <v-btn
                                          @click="$router.back()" 
                                          color="primary darken-2"               
                                          dark
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                        <v-icon>arrow_back</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Regresar a la Página Anterior</span>
                                    </v-tooltip>
                                </template>
                                  
                                  
                                </v-btn-toggle>
                              </v-row>
                            </v-card-text>
                          </v-card>
                          
    </template>
  <!-- FIN Menu -->

<!-- SALIDAS DEL PRODUCTO -->
<h4>Salidas </h4>
  <v-btn
                    color="green lighten-2"
                    text
                    @click="getSalesOrders()"
                  >
                    Cargar Datos
  </v-btn>
  <v-btn
                    color="default lighten-2"
                    @click="showsalesorder=false"
                    text
                  >
                    cerrar
                  </v-btn>
    
  <br><br>

  <v-row>
      <v-col cols="12" sm="12" md="12" lg="12"    
        v-if="showsalesorder==true"
      >
        <SalesOrderItem :salesorders="rows"  />
      </v-col>
  </v-row>
<!-- FIN SALIDAS DEL PRODUCTO -->

<!-- COMPRAS DEL PRODUCTO -->
<h4>Compras</h4>
                  <v-btn
                    color="green lighten-2"
                    text
                    @click="getPurchases()"
                  >
                    Cargar Datos
                  </v-btn>
                  <v-btn
                    color="default lighten-2"
                    @click="showpurchases=false"
                    text
                  >
                    cerrar
                  </v-btn>
    
                  <br><br>
  <v-row>
      <v-col cols="12" sm="12" md="12" lg="12"    
        v-if="showpurchases==true"
      >
        <PurchaseItem :purchases="purchases"  />      
      </v-col>      
  </v-row>
<!-- FIN COMPRAS DEL PRODUCTO -->
 
<!-- ENTRADA/DEVOLUCION DEL PRODUCTO -->
<h4>Devoluciones </h4>
                  <v-btn
                    color="green lighten-2"
                    text
                    @click="getReturns()"
                  >
                    Cargar Datos
                  </v-btn>
                  <v-btn
                    color="default lighten-2"
                    @click="showreturns=false"
                    text
                  >
                    cerrar
                  </v-btn>
    
  <br><br>
  <v-row>
      <v-col cols="12" sm="12" md="12" lg="12"    
        v-if="showreturns==true"
      >
        <!-- Busqueda Return -->
        <ReturnItem :returns="comeback"  />
        <!-- FIN Busqueda Return -->
      </v-col>      
  </v-row>
<!-- FIN ENTRADA/DEVOLUCION DEL PRODUCTO -->


  <!-- Cotizaciones DEL PRODUCTO -->
  <h4>Cotizaciones</h4>
                  <v-btn
                    color="green lighten-2"
                    @click="getQuotes()"
                    text
                  >
                    Cargar Datos
                  </v-btn>
                  <v-btn
                    color="default lighten-2"
                    @click="showquotes=false"
                    text
                  >
                    cerrar
                  </v-btn>
    
                  <br><br>
  <v-row>
      <v-col cols="12" sm="12" md="12" lg="12"    
        v-if="showquotes==true"
      >
        <!-- Busqueda Quote -->
        <QuoteItem :quotes="quotes"  />
        <!-- FIN Busqueda Quote -->
      </v-col>      
  </v-row>



  <!-- DIALOG NOTAS -->
  <div class="text-center">
            <v-dialog
              v-model="dialogNotes"
              width="30%"
            >
              <v-card>
                <v-card-title class="headline grey lighten-2">
                  Notas
                </v-card-title>
                <v-card-text class="my-5">
                  {{notes}}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="dialogNotes = false"
                  >
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
    </div>
    <!-- FIN DIALOG NOTAS -->

    


  </div>
</template>


<script>
// import axios from "axios";
// import{ mapMutations } from "vuex";
import{ mapMutations,mapState } from "vuex";
//import FieldSearch from '@/components/FieldSearch.vue'
import baseApi from '@/services/baseApi';
import SalesOrderItem from "@/components/SalesOrderItem";
import PurchaseItem from "@/components/PurchaseItem";
import ReturnItem from "@/components/ReturnItem";
import QuoteItem from "@/components/QuoteItem";


export default {
  name: 'productsaleOrder',
  components: {
    //FieldSearch,
    SalesOrderItem,
    PurchaseItem,
    ReturnItem,
    QuoteItem

  },
  computed:{
    ...mapState(['purchases','quotes','comeback']) 
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]), 
    downloadExcel(){

    },
    getNote(note){
      // console.log('Producto ID:'+ProductID);
      this.notes=note;
      // alert(this.productid);
      this.dialogNotes =true;  
    }, 
    url: function (action,href){
      if (action=='Return') {
        return 'detalledevolucion?returnid='+ href    
      }else if (action=='SalesOrder') {
        return 'detalleventas?salesorderid='+ href
      }else if (action=='Purchases') {
        return 'detallecompra?purchaseid='+ href
      }else if (action=='Quotes') {
        return 'detallecotizacion?quoteid='+ href
      } else {
        return 'detalleventascliente?customerid='+ href        
      }
    },
    async getBreadcrumps(sword){
      console.log(sword);
      this.changeBreadcrumbs({
        page:"Ventas",
        title:"Detalle de Ventas por Producto",
        subtitle:"primary",
        description:"En este stock encontrará todas las ventas, gastos dados de alta en el sistema desde el principio de los tiempos"
       });
      //let aFecha=dia.split(["-"])
      //let ddmmyy=aFecha[2]+"-"+aFecha[1]+"-"+aFecha[0];

      try{        
        this.showLoading({titulo:"Accediendo información",color:"primary"})        
        let datos = await baseApi().get(`/clientes/getall`);
        //console.log(datos);
        if(datos.data.length >0){
          this.customers=datos.data
        }else{
          this.customers="Sin resultados";
        }
      }catch(error){
        //console.log(error);
      } finally{
        this.hiddenLoading()
      } 
    },
    async getSalesOrders(){
       // alert('hola')
       try{       
          this.showLoading({titulo:"Accediendo información",color:"primary"})        
          let datos = await baseApi().get(`/notaremision/getall?valor=2019-01-01|`+this.sfecha+`&word=${this.sword}&tipo=product&pricelistid=`+this.$route.query.pricelistid);
          //console.log(datos.data);        
          this.showsalesorder=true;
          
          if(datos.data.length >0){
            this.rows=datos.data    
            //var total2=0;    
            for (var i = 0; i < this.rows.length; i+=1) { 
               this.productname=this.rows[0].productname; 
               this.branchname=this.rows[0].branchname; 
            //   //console.log("PRICE: "+this.rows[i].price);            
            //   // if (this.rows[0].salesorderdiscount!=0) this.desc=(this.salesorder_subtotal*(this.rows[0].salesorderdiscount/100)); 
            //   // else this.desc=0;

            //   this.subtotal=(this.subtotal + (this.rows[i].subtotal_salesorders));  
            //   this.iva=(this.iva + (this.rows[i].iva_salesorders));              
            //   this.total=(this.total + (this.rows[i].total_salesorders));  
             }      
            //console.log(this.total);
            //console.log(1+this.rows[1].total*1);
            //this.desserts=datos.data          
          }else{
            // this.rows="Sin resultados";
          }

        }catch(error){
            //console.log(error);
        }  finally{
            this.hiddenLoading()        
        } 
    },
    async getQuotes(){
       // alert('hola')
       try{       
          this.showLoading({titulo:"Accediendo información",color:"primary"})  
          this.showquotes=true;
          this.attribute.pricelistid=this.$route.query.pricelistid;
          await this.$store.dispatch('loadQuotes2',this.attribute); //component PricelistItem
        }catch(error){
            //console.log(error);
        }  finally{
            this.hiddenLoading()        
        } 
    },
    
    async getReturns(){
       // alert('hola')
       try{       
         this.showLoading({titulo:"Accediendo información",color:"primary"})  
         this.showreturns=true;
        //  this.attribute.tipo='product';
         this.attribute.pricelistid=this.$route.query.pricelistid;
          // console.log(this.attribute.pricelistid);

         await this.$store.dispatch('loadComeback',this.attribute); //component PricelistItem
        }catch(error){
            //console.log(error);
        }  finally{
            this.hiddenLoading()        
        } 

    },
    async getPurchases(){
      // alert('hola')
      // this.purchase_subtotal=0;
      // this.purchase_total=0;

      try{       
        // this.sfecha=new Date().toISOString().substr(0,10); 
        // console.log('fecha '+this.sfecha);
        this.showLoading({titulo:"Obteniendo Compras de este Producto",color:"primary"})      
        this.showpurchases=true;
        this.attribute.pricelistid=this.$route.query.pricelistid;
        await this.$store.dispatch('loadPurchases2',this.attribute); //component PricelistItem
        // let datos = await baseApi().get(`/compras/getall?valor=2019-01-01|`+this.sfecha+`&word=&tipo=product&pricelistid=`+this.$route.query.pricelistid);
        // console.log(datos.data);        
        // console.log(datos.data.length);  
        //this.Piva=this.Psubtotal=this.Ptotalline=0;      

        // if(datos.data.length >0){
        //   this.rPurchases=datos.data
        // }
          // for (var i = 0; i < this.purchases.length; i+=1) { 
            
            // this.Ptotalline=this.purchases[i].price*this.purchases[i].cantidad;
        //     this.Psubtotal=this.Psubtotal + this.Ptotalline;  
        //     if (this.rPurchases[i].iva==1) {
        //       this.Piva=this.Piva + (this.Ptotalline*1.16-this.Ptotalline);  
        //     }else this.Piva=this.Piva+0;  

        //   }      
        //   this.Ptotal=this.Psubtotal + this.Piva;  
        //   //console.log(this.total);
        // }else{
        //   // this.rPurchases="Sin resultados";
        // }

      }catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()        
      } 
    }
  },
  data(){
     return{
      dialogNotes:false,
      notes:'',
      toggle_exclusive:'',
      attribute: {
        sword: 'All',
        date_start:'2019-01-01',
        date_end:new Date().toISOString().substr(0,10),
        tipo:'product',
        pricelistid:'',
        limit: 27
      },
      sword:"",
      dialog:false,
      // editRow:null,
      showsalesorder:false,
      showpurchases:false,
      showreturns:false,
      showexpensives:false,
      showquotes:false,
      branchname:"",      
      productname:"",
      iva:0,
      subtotal:0,
      total:0,
      subtotalline:0,
      lineiva:0,
      Ptotalline:0,
      Pivatotal:0,
      Psubtotal:0,
      Ptotal:0,
      sfecha:new Date().toISOString().substr(0,10),
      rows: [],
      rPurchases: [],
      purchase_subtotal: 0,
      purchase_total: 0,
      purchase_tax: 16,
      purchase_line_total:0,
      rQuote: [],
      theader: [
        { text: 'Acción', value: 'canasta_basica' },
        {
          text: 'Descripcion',
          align: 'start',
          sortable: false,
          value: 'id',
        },        
        { text: 'Subtotal', value: 'amount_other' },
        { text: 'IVA', value: 'cantidad' },
        { text: 'Total', value: 'cantidad' },
        { text: 'Notas', value: 'notes' },        
      ],
      theaderCompras: [
        { text: 'Acción', value: 'canasta_basica' },
        {
          text: 'Descripcion',
          align: 'start',
          sortable: false,
          value: 'id',
        },        
        { text: 'Subtotal', value: 'amount_other' },
        { text: 'IVA', value: 'cantidad' },
        { text: 'Total', value: 'cantidad' },
        { text: 'Notas', value: 'notes' },       
      ],
      theaderReturn: [
        { text: 'Acción', value: 'canasta_basica' },
        {
          text: 'Descripcion',
          align: 'start',
          sortable: false,
          value: 'id',
        },        
        { text: 'Total', value: 'cantidad' },
        { text: 'Notas', value: 'notes' },       
      ],
    }
  },
  created(){
    this.getBreadcrumps(this.sword)    
  }
}
</script>