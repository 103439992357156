<template>
  <div id="ProductEditMeli">
    <!-- success msg component 
    <Success :msg="msg" v-show="success" />
    -->
    <!-- edit user which has user data as prop -->
    <ProductFormMeli :product="product" :submitForm="updateRow" buttonText="Actualizar"  hasProduct=true hasLoadProduct=true :hasValorID='product.pricelist_id' />
  
  </div>
</template>

<script>
import{ mapMutations,mapState } from "vuex";
import ProductFormMeli from "@/components/ProductFormMeli";
//import Success from "./Success";
import baseApi from '@/services/baseApi';
import router from '@/router';


export default {
  name: "ProductEditMeli",
  computed:{
    ...mapState(['currentUser']),    
  },
  components: {
    ProductFormMeli,
    //Success,
  },
  props: {
    product: Object,
  },
  data() {
    return {
      success: false,
      msg: "",
      id:23
    };
  },
  methods: {
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),
    async updateRow2(productInfo){
      //updateRow(user) {     
      alert('hola: '+ productInfo.pricelistmeli.meli_id) ;
    },
    async updateRow(productInfo){
      //updateRow(user) {     
      //alert('hola: '+ productInfo.id) ;
      try{        
          this.showLoading({titulo:"Accediendo información",color:"warning"})  
          baseApi().post(`/precios/meliupdate/${productInfo.id}`,JSON.stringify(productInfo.pricelistmeli)).then((response)=>{        
            if(!response.data.error){                            
              this.$swal("Genial!",response.data.message, "success");
            }else{
              this.$swal("Error!",response.data.message, "error");
            }                                    
          });              
        }catch(error){
          console.log(this.$route.name+' '+error);          
          if (error.message=='Request failed with status code 500') {
            this.$swal("Error!",error.message+"<br/> Intente Nuevamente o verifique todos los campos", "error");
          }else if(error.message=='Request failed with status code 401'){
            this.$swal("Error!", "Ha terminado la sesion <br/>Por seguridad ingrese de nuevo sus credenciales", "error");
            router.push('/login');
          }else{
            this.$swal("Error!", "Algo salio mal, Intente nuevamente <br/>"+error, "error");
          } 
        }finally{
          this.hiddenLoading()
        }
    }
  },
  created(){
    //Producto ID
    //this.getDataProduct(23);
  },
  updated(){
    //this.getDataProduct(23);
    //alert('cambio')
  }
};
</script>