
<template>
  <div class="detailsalesorder"> 
    <template>
      <v-container class="grey lighten-5">
          <v-row>
            <v-col>
              <h5>Buscar:</h5>
              <input type="text" placeholder="Escribe un producto" v-model="sword" v-on:keyup.13="getBreadcrumps(sword)">
            </v-col>
          </v-row>                                                                            
      </v-container>
    </template>

  <v-row>
    <v-col cols=12 sm=12 md=6 lg=6>      
       <p style="text-align:left; font-size:1.1rem;"><b>Folio: </b> {{ folio }}</p>
       <p style="text-align:left; font-size:1.1rem;"><b>Cliente: </b> {{ customerfullname }}</p>
       <p style="text-align:left; font-size:1.1rem;"><b>Fecha: </b> {{ date_salesorder }}</p>              
    </v-col>
    <v-col cols=12 sm=12 md=6 lg=6>      
      <h5>Notas:</h5>
      {{salesnotes}}
    </v-col>    
  </v-row>


  
        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }"> 
                <v-btn
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-dialog
                    v-model="dialog2"
                    persistent
                    max-width="75%"                 
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="green"            
                        v-bind="attrs"
                        v-on="on"
                        plain
                      >
                        <v-icon color="white">edit</v-icon> 
                      </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                          <span class="headline">Editar Cliente: {{ customerfullname }}</span>
                          <v-btn
                            class="headline"
                            color="blue darken-1"
                            text
                            @click="dialog2 = false"
                          >
                            <v-icon>close</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-container> 
                            <CustomerEdit :user="user" />
                            <!--
                              <CustomerForm :submitForm="DetailCustomer" :user="user" hasCustomer=true buttonText="Guardar"  /> 
                            -->                      
                          </v-container>
                          
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>          
                          <small style="color:red;">*Campos Obligatorios</small>
                          <!-- <v-btn 
                            color="blue darken-1"
                            text
                            v-on:click="submit"><v-icon>save</v-icon>
                          </v-btn>         -->
                        </v-card-actions>
                      </v-card>
                      </v-dialog>
                </v-btn>                
              </template>
              <span>Editar Cliente</span>
            </v-tooltip>
          </template>  

<!-- Dialog Confirmación -->

<!-- FIN Dialog confirmación -->

<!-- Tools -->
<v-card
    flat
    class="py-12"
  >
    <v-card-text>
      <v-row class="text-right" justify="center" >        
        <v-btn-toggle
          v-model="toggle_exclusive"
          mandatory
        >
          <template>  
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">              
                  <v-btn
                    @click="downloadPDF"
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>picture_as_pdf</v-icon>
                  </v-btn>
                </template>
                <span>Descargar Salida en PDF</span>
              </v-tooltip>
          </template>  
          <template>  
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">              
                  <v-btn
                    @click="downloadPDFTicket"
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>picture_as_pdf</v-icon>
                  </v-btn>
                </template>
                <span>Descargar Salida en PDF Ticket</span>
              </v-tooltip>
          </template>                
          <template>  
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">              
                  <v-btn
                    @click="ChecklistPDF"
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>picture_as_pdf</v-icon>
                  </v-btn>
                </template>
                <span>Descargar Lista Entregable en PDF</span>
              </v-tooltip>
          </template> 
          
          <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <v-btn
                  @click="orderByAsc"                  
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>format_list_numbered</v-icon>
                </v-btn>
              </template>
              <span>Ordenar la lista de productos</span>
            </v-tooltip>
          </template>    
          
          <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }"> 
                <v-btn
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="50%"                 
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="green"            
                        v-bind="attrs"
                        v-on="on"
                        plain
                      >
                        <v-icon>add</v-icon> 
                      </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                          <span class="headline">Agregar Producto / Servicio</span>
                          <v-btn
                            class="headline"
                            color="blue darken-1"
                            text
                            @click="dialog = false"
                          >
                            <v-icon>close</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-container> 
                            <!--<ProductForm :submitForm="DetailProduct" hasSalesOrder=true buttonText="Guardar"  />-->  
                            <ProductSalesOrderCreate :user="user" />
                          </v-container>
                          
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>          
                          <small style="color:red;">*Campos Obligatorios</small>
                          <!-- <v-btn 
                            color="blue darken-1"
                            text
                            v-on:click="submit"><v-icon>save</v-icon>
                          </v-btn>         -->
                        </v-card-actions>
                      </v-card>
                      </v-dialog>
                </v-btn>                
              </template>
              <span>Agregar Producto</span>
            </v-tooltip>
          </template>  
          <!-- Dialog para agregar --> 

          <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <v-btn
                  @click="getDetailSalesOrder()"                   
                  color="deep-darken"               
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>refresh</v-icon>
                </v-btn>
              </template>
              <span>Refrescar la Pagina</span>
            </v-tooltip>
        </template>
                  
        <template>  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">              
                <v-btn
                  @click="$router.back()" 
                  color="primary darken-2"               
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                <v-icon>arrow_back</v-icon>
                </v-btn>
              </template>
              <span>Regresar a la Página Anterior</span>
            </v-tooltip>
          </template>
          
        </v-btn-toggle>
      </v-row>
    </v-card-text>
  </v-card>
  
  
  <!-- Lado Izquierdo -->
  <v-row v-if="rows.length > 0">
    <v-col cols="12" xs="12" sm="12" md="8" lg="10" xl="10">    
      <table class="table table-striped">
        <thead class="thead-dark">
          <tr><th v-for="thead in theader" :key="thead.id">{{thead.text}}</th></tr>               
        </thead>     
        <tr v-for="(row) of rows" :key="row.id">       
          <td>          
          <v-img
          :src="row.productfilename"
          :lazy-src="row.productfilename"
          :alt="`${row.productname} avatar`"
          aspect-ratio="1"
          class="grey lighten-2"
          max-height="95"
          max-width="95"
          ></v-img>            
          <br><br>
          <template>  
                      <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">              
                              <v-btn
                                color="red"
                                x-small
                                fab
                                @click="deleteRow(row.id)"
                                v-bind="attrs"
                                v-on="on"                                
                              >
                                <v-icon>delete</v-icon>
                              </v-btn>
                            </template>
                            <span>Borrar Producto</span>
                          </v-tooltip>
                      </template>                                            
                      
                      <template>  
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">              
                              <v-btn
                                color="secondary"
                                x-small
                                fab
                                @click="editRow = row.id"
                                v-bind="attrs"
                                v-on="on"                                
                              >
                                <v-icon>edit</v-icon>
                              </v-btn>
                            </template>
                            <span>Editar Producto</span>
                          </v-tooltip>
                      </template> 
                      <template> 
                                        <v-tooltip top>
                                          <template v-slot:activator="{ on }">   
                                            <v-btn
                                                color="blue"
                                                x-small
                                                fab
                                                v-on:click="getProduct(row.pricelist_id)"                                  
                                                v-on="on"
                                            >
                                                <v-icon>visibility</v-icon>                  
                                            </v-btn>            
                                          </template>
                                          <span>Detalle Producto</span>
                                        </v-tooltip>
                        </template>                              

                        
        </td> 
        <td>
            <a :href="row.url" target="_blank">{{row.productname}}</a>
            
            <v-btn
                            class="headline"
                            color="blue darken-1"
                            text
                            @click="getDataProduct(row)"
                          >
                             <v-icon>edit</v-icon>
                          </v-btn>
                          <br>
                          <BarcodeGenerator
                          :value="row.pricelist_id"
                          :format="'CODE39'"
                          :lineColor="'#000'"
                          :width="2"
                          :height="50"
                          :elementTag="'img'"
                          :displayValue=false
                          />  
                          <br>
            <small><b>ID:</b> {{row.id}}</small> | <small> <b>Producto ID:</b> {{row.productid}}</small> | <small><b>SKU:</b> {{row.productsku}}</small> <br>
            <small><b>Marca:</b> {{row.brandname}}</small> | <small><b>Modelo:</b> {{row.productskufab}}</small> <br>                                  
            <small><b>Clave SAT:</b> {{row.productclavesat}}</small> | <small><b>SALIDA ID:</b> {{row.salesorder_id}}</small> <br>                                            
            Regular: <span style="font-size:.1.2rem; color:red;">{{row.totalprice_siniva | currency }} <small>  s/iva</small></span><br>
            Mayorista: <span style="font-size:.1.2rem; color:red;">{{row.price_mayorista_siniva | currency }} <small>  s/iva</small></span><br>
            Minorista: <span style="font-size:.1.2rem; color:red;">{{row.price_minorista_siniva | currency }} <small>  s/iva</small></span><br>
            Público: <span style="font-size:.1.2rem; color:red;">{{row.price_publico_siniva | currency }} <small>  s/iva</small></span><br>
            Fecha Creación: {{row.created_at}} <br> 
            Fecha Actualización: {{row.updated_at}} <br> <br>
            <div v-if="editRow === row.id">
            <!-- <input v-on:keyup.13="updateRow(row)" placeholder="Cotización ID" v-model="row.quote_id" /> -->
            <v-autocomplete
              v-model="row.pricelist_id"
              :items="priceslist"
              item-text="description"
              item-value="id"
              label="Selecciona un producto*"
              outlined
            ></v-autocomplete>                                                
            <v-text-field
                        v-model="row.posicion"
                        v-on:keyup.13="updateRow(row)"
                        label="Posición"
            />  
            <v-text-field
                        v-model="row.price"
                        v-on:keyup.13="updateRow(row)"
                        label="Precio"
            />  
            <v-text-field
                        v-model="row.cantidad"
                        v-on:keyup.13="updateRow(row)"
                        label="Cantidad"
            /> 
            <v-text-field
                        v-model="row.discount"
                        v-on:keyup.13="updateRow(row)"
                        label="Descuento"
            /> 
            <v-row>
              <v-col cols=12>
                <v-textarea
                            v-model="row.notes"
                            filled
                            auto-grow
                            label="Escribe comentarios"
                            rows="4"
                            row-height="30"
                            shaped
                ></v-textarea>    
              </v-col>
            </v-row>        
            <v-btn v-on:click="updateRow(row)" class="green">Actualizar</v-btn>
            <v-btn v-on:click="editRow=false" class="deep-black">Cerrar</v-btn>
          </div>
          <div v-else>  </div>  
        </td>
        <td>
          <b>Precio de Lista </b>  <br> {{row.price | currency }} <br>  
          <b>Descuento </b> <br> {{(row.price-(row.price*(row.discount/100))) | currency }}  <small>({{row.discount}}%)</small>
        </td>        
        <td>
          {{row.cantidad}}
        </td>
        <td>
          {{ calculateLineTotal(row) | currency }}        
        </td>           
        <td>
          <div v-if="row.notes != null && row.notes != ''">   
            <v-btn
                                color="red lighten-2"
                                dark
                                @click="getNote(row.notes)" 
                              
                              >
                                ver nota
                              </v-btn>
          </div>
        </td>
        <td></td>        
        <td></td>      
      </tr>
    </table>     
    
    <v-row>
      <v-col cols=12 sm=12 md=12 lg=12>   
        <table>
          <tr>
            <td style="text-align:left; font-size:1.1rem;"></td> 
            <td style="text-align:right; font-size:1.1rem;"><b>Subtotal:</b> {{ salesorder_subtotal | currency }}</td> 
          </tr>
          <tr>
            <td style="text-align:left; font-size:1.1rem;"></td> 
            <td style="text-align:right; font-size:1.1rem;"><b>Desc:</b> {{ desc | currency }}</td>  
          </tr>
          <tr>
            <td style="text-align:left; font-size:1.1rem;"></td> 
            <td style="text-align:right; font-size:1.1rem;"><b>Subtotal c/Desc:</b> {{ salesorder_subtotal2 | currency }}</td>  
          </tr>
          <tr>
            <td style="text-align:left; font-size:1.1rem;"></td> 
            <td style="text-align:right; font-size:1.1rem;"><b>IVA:</b> {{ iva | currency }}</td>  
          </tr>
          <tr>
            <td style="text-align:left; font-size:1.1rem;"></td> 
            <td style="text-align:right; font-size:1.1rem;"><b>Total:</b> {{ salesorder_total | currency }}</td> 
          </tr>
        </table>
      </v-col>
    </v-row>      
  </v-col>
  <!-- Lado Derecho -->
  <v-col cols="12" xs="12" sm="12" md="4" lg="2" xl="2">    
    <h5 >Ordenar Lista {{ draggingInfo }}</h5>
    <div class="form-check">
          <input
            id="disabled"
            type="checkbox"
            v-model="enabled"
            class="form-check-input"
          />
          <label class="form-check-label" for="disabled">Habilitar</label>
    </div>
    <br><br>
      <v-card
        class="mx-auto"
        max-width="100%"
      >
        <v-list>
          <v-list-item-group       
            mandatory     
            color="indigo"
          >
              <draggable
                :list="rows"
                :disabled="!enabled"
                class="list-group"
                ghost-class="ghost"
                :move="checkMove"
                @start="dragging=false"
                @end="order2()"
              >
                <v-list-item
                  v-for="(row,index) in rows"
                  :key="row.id"                  
                  v-slot="{ active, toggle }"
                >
                  <v-list-item-avatar>
                    <v-img
                        :src="row.productfilename"
                        :lazy-src="row.productfilename"
                        :alt="`${row.productname} avatar`"
                        aspect-ratio="1"
                        class="grey lighten-2"
                        max-height="95"
                        max-width="95"
                    ></v-img>                    
                  </v-list-item-avatar>
                  <v-list-item-content
                    :color="active ? 'primary' : ''"
                    @click="toggle"
                  >                    
                    <v-list-item-title>
                      <a :href="row.url" target="_blank">{{row.productname}}</a>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <small><b>Marca:</b> {{row.brandname}}</small> | <small><b>Modelo:</b> {{row.productskufab}}</small> <br>                      
                      <small><b>SKU:</b> {{row.productsku}}</small><br>
                      <small><b>ID:</b> {{row.id}}</small> | <small> <b>Producto ID:</b> {{row.productid}}</small><br>
                      Index: {{index+1}} -  Posicion {{row.posicion}}
                    </v-list-item-subtitle>                    
                  </v-list-item-content>                   
                </v-list-item>
              </draggable>
          </v-list-item-group>
        </v-list>     
      </v-card>  
    </v-col>
  </v-row>
  <v-row v-else>
    <v-col cols=12 sm=12 md=12 lg=12 >
    <v-alert
            dense
            outlined
            type="error"
          >
            No hay registros encontrados con esta busqueda
      </v-alert>
    </v-col>
  </v-row>

<!-- VISTA PARA EDITAR PRODUCTO -->
<template>                 
                      <v-dialog
                        v-model="dialog10"
                        persistent
                        max-width="800px"                 
                      >
                     
                          <v-card>
                            <v-card-title>
                              <span class="headline">Editar Producto </span>
                              <v-btn
                                class="headline"
                                color="blue darken-1"
                                text
                                @click="dialog10 = false"
                              >
                                <v-icon>close</v-icon>
                              </v-btn>
                            </v-card-title>
                            <v-card-text>
                              <v-container v-if='this.dialog10==true'>
                                <ProductEdit :product="product" />                                
                              </v-container>
                          </v-card-text>
                          <v-card-actions>
                              <v-btn 
                                color="red" 
                                @click="dialog10 = false"
                              >
                                cerrar
                              </v-btn>
                              <v-spacer></v-spacer>   
                              <small style="color:red;">*Campos Obligatorios</small>
                            </v-card-actions>
                          </v-card>                    
                      </v-dialog>
              </template>    
<!-- FIN VISTA PARA EDITAR PRODUCTO -->

      <!-- VISTA PRODUCTO DIALOG-->
      <template>                 
                      <v-dialog
                        v-model="dialogViewProduct"
                        persistent
                        max-width="800px"                 
                      >
                          <v-card>
                            <v-card-title>
                              <span class="headline">Detalle Producto</span>
                              <v-btn
                                class="headline"
                                color="blue darken-1"
                                text
                                @click="dialogViewProduct = false"
                              >
                                <v-icon>close</v-icon>
                              </v-btn>
                            </v-card-title>
                            <v-container v-if='this.dialogViewProduct==true'>
                                <ProductView :productid="productid"  />
                            </v-container>
                            <!-- :pricelist="priceslist -->
                            <!-- buttonText="Guardar"   -->
                          <v-card-actions>
                              <v-btn 
                              
                              color="red" 
                              @click="dialogViewProduct = false"
                              >
                              cerrar
                            </v-btn>
                              <v-spacer></v-spacer>              
                            </v-card-actions>
                          </v-card>                    
                      </v-dialog>
      </template>                 
      <!-- FIN VISTA PRODUCTO  DIALOG-->
       

   <!-- DIALOG NOTAS -->
   <div class="text-center">
            <v-dialog
              v-model="dialogNotes"
              width="30%"
            >
              <v-card>
                <v-card-title class="headline grey lighten-2">
                  Notas
                </v-card-title>
                <v-card-text class="my-5">
                  {{notes}}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="dialogNotes = false"
                  >
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
    </div>
    <!-- FIN DIALOG NOTAS -->
  
  </div>
</template>

<script>
  // import axios from "axios";
  import draggable from "vuedraggable";
  import{ mapMutations,mapState } from "vuex";
  //import FieldSearch from '@/components/FieldSearch.vue'
  import jsPDF from 'jspdf' 
  import domtoimage from "dom-to-image";
  //import DetailQuoteAdd from "@/components/DetailQuoteAdd.vue";
  import baseApi from '@/services/baseApi';
  import ProductSalesOrderCreate from "@/components/ProductSalesOrderCreate";
  import CustomerEdit from "@/components/CustomerEdit";
  import ProductEdit from "@/components/ProductEdit";
  import ProductView from "@/components/ProductView";
  import BarcodeGenerator from "@/components/BarcodeGenerator";


  
  export default {
    name: 'detailsalesorder',
    computed:{
      ...mapState(['currentUser','priceslist']),
      draggingInfo() {
        return this.dragging ? "...arrastrando" : "";
      } 
    },  
    components: {
      //DetailQuoteAdd,
      //FieldSearch,
      draggable,
      ProductSalesOrderCreate,
      ProductView,
      ProductEdit,
      CustomerEdit,
      BarcodeGenerator
    },
    methods:{
      ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),
      getDataProduct(row) {       
          this.product=row
          this.dialog10=true
      },
      checkMove: function(e) {      
        window.console.log("Nombre: " + e.draggedContext.element.productname);
        window.console.log("Future index: " + e.draggedContext.futureIndex);
        window.console.log("Origen index: " + e.draggedContext.index);
        window.console.log("destino index: " + e.relatedContext.index);  
        this.index=e.draggedContext.index;
        this.order=e.relatedContext.index;      
        window.console.log("Index: " + this.index);
        window.console.log("Orden: " + this.order);      
      },
      orderByAsc(){
        let i=0;      
        let size=this.rows.length;      
        for (i; i < size; i++) { 
              this.rows[i].posicion = i+1;                       
              this.updateRow(this.rows[i]); //Funciona pero pasa todos los valores
              window.console.log("Posicion: " + this.rows[i].posicion);
        }         
        
      },
      order2(){
        this.dragging=false;
        let i=0;
        let id=this.index;      
        let order=this.order;
        let size=this.rows.length;

        // alert("Por fin lo solto");
        // console.log("ID: "+id)
        // console.log("Size: "+this.list.length)            

        if (order>id){        
            for (i = id; i < size; i++) { 
              this.rows[i].posicion = i+1;                       
            }          
        }else{
          for (i = order; i < size; i+=1) { 
            this.rows[i].posicion = i+1;                       
          }
        }
        
      },    
      calculateLineTotal(row) {            
              var stotal = parseFloat(row.price) * parseFloat(row.cantidad);
              var total = parseFloat(stotal-(stotal*(row.discount/100)));                        
              
              if (!isNaN(total)) {
                  //row.line_total = total.toFixed(2);
                  //total.toFixed(2);                
                  //return total.toFixed(2);
                  return total;
                  
              }
              //return  total;
              //this.calculateTotal(total);
      },    
      getNote(note){
        // console.log('Producto ID:'+ProductID);
        this.notes=note;
        // alert(this.productid);
        this.dialogNotes =true;  
      },  
      async getDetailSalesOrder(){
        // let branch_id=this.currentUser.branch_id;  
        this.salesorder_subtotal=0
        this.salesorder_subtotal2=0
        this.iva=0
        this.desc=0
        this.salesorder_total=0;    
        try{            
          this.showLoading({titulo:"Accediendo información",color:"primary"})
          let branch_id=this.currentUser.branch_id;
          let datos= await baseApi().get(`/detalleventa/`+this.$route.query.salesorderid+`?branch_id=`+branch_id);
          //console.log(datos);
          
          if(datos.data.length >0){
            this.rows=datos.data;  
            for (var i = 0; i < this.rows.length; i+=1) {   
              this.folio=this.rows[0].salesorder_id;            
              this.customerid=this.rows[0].customer_id; 
              this.customerfullname=this.rows[0].customerfullname; 
              this.date_salesorder=this.rows[0].date_salesorder;            
              this.salesnotes=this.rows[0].salesnotes;            
              //this.subtotal=(this.subtotal + (this.rows[i].price*this.rows[i].cantidad));  //Se multiplica con uno para que lo convierta en numero
  
              let total_line=this.calculateLineTotal(this.rows[i]);
              //this.quote_subtotal=(this.quote_subtotal + (total_line-(total_line*(this.rows[i].discount/100))));  //Se multiplica con uno para que lo convierta en numero                        
              this.salesorder_subtotal=this.salesorder_subtotal + total_line;
  
              //console.log("PRICE: "+this.rows[i].price);            
              if (this.rows[0].salesorderdiscount!=0) this.desc=(this.salesorder_subtotal*(this.rows[0].salesorderdiscount/100)); 
              else this.desc=0;
  
              this.salesorder_subtotal2=this.salesorder_subtotal-this.desc;
  
              
              if (this.rows[0].salesiva!=0) this.iva=(this.salesorder_subtotal2*0.16); 
              else this.iva=0;
              this.salesorder_total=this.salesorder_subtotal2 + this.iva;  //Se multiplica con uno para que lo convierta en numero
              this.salesorder_id=this.rows[0].salesorder_id;
            }           
  
            
            //this.desserts=datos.data          
          }else{
            this.rows="Sin resultados";
          }
          
          //Obtenemos Datos del Cliente
          this.getCustomer(this.customerid); 
  
        }catch(error){
          //console.log(error);
        }finally{
          this.hiddenLoading()
        } 

      },
      async getCustomer(customerID){
        try{        
              this.showLoading({titulo:"Accediendo información del Cliente",color:"warning"})
              baseApi().get(`clientes/${customerID}`).then((response)=>{
                if(!response.data.error){
                  this.user=response.data;                             
                  //this.$swal("Genial!",response.data.message, "success");
                }else{
                  this.$swal("Error!",response.data.message, "error");
                }                                    
              });
            }catch(error){
              console.log(error);
            }finally{
              this.hiddenLoading()
            } 
      },
      async getBreadcrumps(){      
        //let aFecha=dia.split(["-"])
        //let ddmmyy=aFecha[2]+"-"+aFecha[1]+"-"+aFecha[0];
        this.getDetailSalesOrder();
  
        
  
        try{        
          this.showLoading({titulo:"Accediendo información",color:"primary"})                        
          await this.$store.dispatch('loadPricelists',"All");
        }catch(error){
          //console.log(error);
        }finally{
          this.hiddenLoading()
        }

          
         

      },
      deleteRow(id) {      
        // alert(id);
        try{        
            this.showLoading({titulo:"Accediendo información",color:"warning"});
            
            if(confirm("Desea borrar definitivamente el registro: "+ id+"?")){
              // alert("Estoy en OK")
              baseApi().get(`/detalleventa/delete/${id}`).then((response)=>{
                if(!response.data.error){                            
                  this.$swal("Genial!",response.data.message, "success");
                }else{
                  this.$swal("Error!",response.data.message, "error");
                }                                    
                console.log("VENTA: "+response.data.message);
              });
            }else{
              // alert("NO acepto :(")
            }          
          }catch(error){
            console.log(error);
          }  finally{
            this.hiddenLoading()
            // this.$swal("Genial!", "Se actualizó la información", "success");
          } 
      },
      ChecklistPDF(){
          const doc = new jsPDF('p', 'mm', [297, 210]); 
          // const doc = new jsPDF('l', 'mm', [297, 210]);           
          var folio2="SO"+this.rows[0].salesorder_id;
          var folio="LE"+this.rows[0].salesorder_id;
          doc.page = 1; // use this as a counter.
          var totalPages = 0; // define total amount of pages
          var leyenda="";
          // HEADER        
          doc.setFontSize(16);//optional
          // doc.setTextColor(40);//optional
          doc.setFont(undefined, "bold");
          doc.text("LISTA DE ENTREGA",30, 18);// set your margins
          doc.setFontSize(10);
          doc.setFont(undefined, "normal");
          doc.text(this.rows[0].title,30, 23);// set your margins
          // FIN FOOTER
                  
        
          // var imgData =this.currentUser.branch_filename;
          var imgData = 'img/logos/'+this.currentUser.branch_filename;
          doc.addImage(imgData, 9, 10, 15, 15);
          // doc.addImage(imgData, 'PNG', 9, 7, 15, 18);
  
  
          //console.log(imgData); 
          
          //doc.text(15, 15, reportTitle);
          //doc.addImage(outputURL, 'JPEG', 15, 40, 180, 100);
  
          
          //var miArray = [ 2, 4, 6, 8, 10 ];
          //console.log(this.rows.length);
          //console.log(this.rows);
          //console.log(this.rows[1].id);        
  
          doc.setFontSize(9);
          doc.text("Folio: "+folio2,147.7,15);
          doc.text("Fecha: "+this.rows[0].created_at,146,21);
          doc.text("Cliente: "+this.rows[0].customerfullname,145,27);
  
                  
          var yh=45;
          doc.setFontSize(8);
          //doc.setFontType("bold"); No funciona
          // this.setFont("helvetica", "bold");
          doc.setFont(undefined, "bold");
          doc.text("",7,yh);
          doc.text("CANT.",15,yh);
          doc.text("DESCRIPCIÓN",25,yh);                
          doc.setFont(undefined, "normal");                  
  
          
          var y=55;           
          var str = "";
          doc.setFontSize(7);
  
          for (var i = 0; i < this.rows.length; i+=1) {               
            if (y>=191)    {
              yh=34;
              y=40;
              totalPages+=1;            
              // FOOTER     
              str = "Página " + doc.page  + " de " +  totalPages;               
              doc.setFontSize(7);// optional
              doc.text(str, 15, doc.internal.pageSize.height - 10);//key is the interal pageSize function                        
              // doc.text("www.ionoff.com.mx  |  (222)207.11.06  |  ventas@ionoff.com.mx", 215, doc.internal.pageSize.height - 10);//key is the interal pageSize function                  
              doc.text(this.currentUser.branch_site+"  |  "+this.currentUser.branch_mobile+"  |  "+this.currentUser.branch_email, 115, doc.internal.pageSize.height - 10);
  
              doc.addPage();  
              doc.setFontSize(10);            
              doc.setFont(undefined, "bold");
              doc.text("",7,yh);
              doc.text("CANT",15,yh);
              doc.text("DESCRIPCIÓN",25,yh);            
              doc.setFont(undefined, "normal");
              doc.setFontSize(7);                            
            }          
            
            // Marco del lado derecho
            // doc.setFillColor(94,101,121);
            // doc.setDrawColor(94,101,121); 
            // doc.rect(0, 0,10, 297, 'FD');
  
            //Circle        
            if ( this.rows[i].type_article ==  "Productos"){
              doc.circle(10, y-1, 2, 'S');          
              doc.text(this.rows[i].cantidad.toString(),18,y,'center'); 
              if ( this.rows[i].url ==  null)
                doc.text(this.rows[i].productname.toString()+"\rSKU: "+this.rows[i].productsku.toString()+"  |  Marca: "+this.rows[i].brandname.toString()+"  |  Modelo: "+this.rows[i].productskufab.toString(),25,y);
              else
                doc.textWithLink(this.rows[i].productname.toString()+"\rSKU: "+this.rows[i].productsku.toString()+"  |  Marca: "+this.rows[i].brandname.toString()+"  |  Modelo: "+this.rows[i].productskufab.toString(), 25, y, {url: this.rows[i].url.toString()});                                     
              //doc.text(String(total | currency).toFixed(2),245,y);
              y = y + 12;                    
            }
          }   
  
          y = y+18;  
  
          doc.line(28, y, 88, y);
          doc.text("Firma de Recibido",58,y+5,'center');         
  
          y = y+6;  
          doc.setFontSize(8);      
  
          //Nota
          if (this.rows[0].salesnotes!=""){
            y = y+30;                
            doc.setFontSize(8);        
            doc.setTextColor(0,0,0);          
            doc.text("NOTA:",10,y);   
            y = y+5;                   
            doc.setTextColor(40);          
            doc.text(this.rows[0].salesnotes,10,y);
          }        
  
  
  
          // FOOTER   
          totalPages+=1;             
          str = "Página " + doc.page  + " de " +  totalPages;        
          doc.setFontSize(7);// optional          
          // doc.setFont("helvetica");
          // doc.setFontType("bold");
          doc.text(str, 15, doc.internal.pageSize.height - 10);//key is the interal pageSize function          
          doc.setTextColor(0,85,136);
          doc.text(leyenda, 43, doc.internal.pageSize.height - 10);//key is the interal pageSize function
          doc.setTextColor(0,0,0);
          // doc.text("www.ionoff.com.mx  |  (222)207.11.06  |  ventas@ionoff.com.mx", 215, doc.internal.pageSize.height - 10);//key is the interal pageSize function
          doc.text(this.currentUser.branch_site+"  |  "+this.currentUser.branch_mobile+"  |  "+this.currentUser.branch_email, 115, doc.internal.pageSize.height - 10);
          
          //doc.text(this.name,15,15);
          doc.output('dataurlnewwindow', {filename: folio+".pdf"});
          // doc.save(folio+".pdf")
      },
      downloadPDF(){
          //const doc = new jsPDF('p', 'mm', [297, 210]); 
          const doc = new jsPDF('l', 'mm', [297, 210]);           
          var folio="SO"+this.rows[0].salesorder_id;
          doc.page = 1; // use this as a counter.
          var totalPages = 0; // define total amount of pages
          var leyenda="";
          // HEADER        
          doc.setFontSize(16);//optional
          // doc.setTextColor(40);//optional
          doc.setFont(undefined, "bold");
          doc.text("SALIDA NORMAL ",30, 18);// set your margins
          doc.setFontSize(10);
          doc.setFont(undefined, "normal");
          doc.text(this.rows[0].title,30, 23);// set your margins
          // FIN FOOTER
  

          var imgData = 'img/logos/'+this.currentUser.branch_filename;
          doc.addImage(imgData, 9, 10, 15, 15);
  
  
          //console.log(imgData);         
          //doc.text(15, 15, reportTitle);
          //doc.addImage(outputURL, 'JPEG', 15, 40, 180, 100);
  
          
          //var miArray = [ 2, 4, 6, 8, 10 ];
          //console.log(this.rows.length);
          //console.log(this.rows);
          //console.log(this.rows[1].id);        
  
          doc.setFontSize(9);
          doc.text("Folio: "+folio,227.7,15);
          doc.text("Fecha: "+this.rows[0].created_at,226,21);
          doc.text("Cliente: "+this.rows[0].customerfullname,225,27);
  
                  
          var yh=45;
          doc.setFontSize(8);
          //doc.setFontType("bold"); No funciona
          // this.setFont("helvetica", "bold");
          doc.setFont(undefined, "bold");
          doc.text("CANT.",7,yh);
          doc.text("DESCRIPCIÓN",17,yh);        
          doc.text("PRECIO DE\rLISTA",205,yh,'center');
          doc.text("DESC.",225,yh,'center')        
          doc.text("PRECIO\rUNITARIO",240,yh,'center')        
          doc.text("IMPORTE",255,yh);
          doc.setFont(undefined, "normal");                  
  
          
          var y=55;   
          var total=0;    
          var stotal=0;   
          var desc=0;   
          var stotal2=0;   
          var iva=0;            
          var gtotal=0;
          var priceuni=0;
          var str = "";
          doc.setFontSize(7);
  
          for (var i = 0; i < this.rows.length; i+=1) {     
            priceuni=this.rows[i].price-(this.rows[i].price*(this.rows[i].discount/100));
            if (y>=191)    {
              yh=34;
              y=45;
              totalPages+=1;            
              // FOOTER     
              str = "Página " + doc.page  + " de " +  totalPages;               
              doc.setFontSize(7);// optional
              doc.text(str, 15, doc.internal.pageSize.height - 10);//key is the interal pageSize function          
              // doc.text("www.ionoff.com.mx  |  (222)207.11.06  |  ventas@ionoff.com.mx", 215, doc.internal.pageSize.height - 10);//key is the interal pageSize function              
              doc.text(this.currentUser.branch_site+"  |  "+this.currentUser.branch_mobile+"  |  "+this.currentUser.branch_email, 215, doc.internal.pageSize.height - 10);
  
              doc.addPage();  
              doc.setFontSize(8);
              //doc.setFontType("bold"); No funciona
              // this.setFont("helvetica", "bold");
              doc.setFont(undefined, "bold");
              doc.text("CANT.",7,yh);
              doc.text("DESCRIPCIÓN",17,yh);        
              doc.text("PRECIO DE\rLISTA",205,yh,'center');
              doc.text("DESC.",225,yh,'center')        
              doc.text("PRECIO\rUNITARIO",240,yh,'center')        
              doc.text("IMPORTE",255,yh);
              doc.setFont(undefined, "normal");
              doc.setFontSize(7);                            
            }
            total=0;    
            //total=this.rows[i].cantidad*this.rows[i].price;
            total=this.calculateLineTotal(this.rows[i]);          
            stotal+=total;
            //console.log("En el índice '" + i + "' hay este valor: " + this.rows[i].id);
            //console.log("total: " + total);
            
            doc.text(this.rows[i].cantidad.toString(),10,y,'center'); 
            if ( this.rows[i].url ==  null)
              doc.text(this.rows[i].productname.toString()+"\rSKU: "+this.rows[i].productsku.toString()+"  |  Marca: "+this.rows[i].brandname.toString()+"  |  Modelo: "+this.rows[i].productskufab.toString(),17,y);
            else
              doc.textWithLink(this.rows[i].productname.toString()+"\rSKU: "+this.rows[i].productsku.toString()+"  |  Marca: "+this.rows[i].brandname.toString()+"  |  Modelo: "+this.rows[i].productskufab.toString(), 17, y, {url: this.rows[i].url.toString()});                   
            doc.text("$"+this.rows[i].price.toString().replace(/\d(?=(\d{3})+\.)/g, '$&,'),205,y,'center');          
            doc.text("-"+this.rows[i].discount.toString()+"%",225,y,'center');
            doc.text("$"+priceuni.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),240,y,'center');
            doc.text("$"+total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),255,y);
            //doc.text(String(total | currency).toFixed(2),245,y);
            y = y + 12;          
          }   
          
          if (this.rows[0].salesorderdiscount!=0) desc=stotal*(this.rows[0].salesorderdiscount/100);
          else desc=0;
  
          stotal2=stotal-desc;
          
  
          if (this.rows[0].iva!=0) iva=stotal2*0.16;
          else iva=0;
          
          gtotal=stotal2+iva;        
  
          y = y+6;  
          doc.setFontSize(8);      
          doc.text("SUBTOTAL:",250,y,'right'); 
          doc.text("$"+stotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),268,y,'right'); 
          y = y+6;   
          if (desc>=0){
            doc.text("DESCUENTO:",250,y,'right'); 
            doc.text("$"+desc.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),268,y,'right'); 
            y = y+6;        
            doc.text("SUBTOTAL c/DESC:",250,y,'right'); 
            doc.text("$"+stotal2.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),268,y,'right');             
            y = y+6;        
          }                                
          doc.text("IVA:",250,y,'right'); 
          doc.text("$"+iva.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),268,y,'right');           
          y = y+6;        
          // doc.setFontSize(9);
          doc.text("TOTAL:",250,y,'right'); 
          doc.text("$"+gtotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),268,y,'right');
  
          //Nota
          if (this.rows[0].salesnotes!=""){
            y = y+30;                
            doc.setFontSize(8);        
            doc.setTextColor(0,0,0);          
            doc.text("NOTA:",10,y);   
            y = y+5;                   
            doc.setTextColor(40);          
            doc.text(this.rows[0].salesnotes,10,y);
          }        
  
  
  
          // FOOTER   
          totalPages+=1;             
          str = "Página " + doc.page  + " de " +  totalPages;        
          doc.setFontSize(7);// optional          
          // doc.setFont("helvetica");
          // doc.setFontType("bold");
          doc.text(str, 15, doc.internal.pageSize.height - 10);//key is the interal pageSize function          
          doc.setTextColor(0,85,136);
          doc.text(leyenda, 43, doc.internal.pageSize.height - 10);//key is the interal pageSize function
          doc.setTextColor(0,0,0);
          // doc.text("www.ionoff.com.mx  |  (222)207.11.06  |  ventas@ionoff.com.mx", 215, doc.internal.pageSize.height - 10);//key is the interal pageSize function          
          doc.text(this.currentUser.branch_site+"  |  "+this.currentUser.branch_mobile+"  |  "+this.currentUser.branch_email, 215, doc.internal.pageSize.height - 10);
          
          //doc.text(this.name,15,15);
          doc.save(folio+".pdf")
          // doc.output('dataurlnewwindow', {filename: folio+".pdf"});
      },
      downloadPDFTicket(){          
          // var opciones = {
          //     orientation: 'p',
          //     unit: 'mm',
          //     format: [55, 300]
          // };
          // var doc = new jsPDF(opciones);       
          const doc = new jsPDF('p', 'mm', [55, 180]);              
          var folio="SOT"+this.rows[0].salesorder_id;

          var imgData = 'img/logos/'+this.currentUser.branch_filename;
          doc.addImage(imgData, 20, 3, 15, 15);   
          var yhe=23;
          doc.setFontSize(7);
          doc.setFont(undefined, "normal");                            
          doc.text(this.currentUser.branch_giro,27,yhe,'center');
          doc.text(this.currentUser.branch_address, 27,yhe+3.5,'center');
          doc.text("C.P. "+this.currentUser.branch_cp+"  |  "+this.currentUser.branch_rfc,27,yhe+7,'center');
          
          var yh=38;
          // doc.setFontSize(8);
          //doc.setFontType("bold"); No funciona
          // this.setFont("helvetica", "bold");
          doc.setFont(undefined, "bold");
          doc.text("DESCRIPCIÓN",2,yh);        
          doc.text("CANT.",30,yh);          
          doc.text("PRECIO",46,yh,'center');
          // doc.text("DESC.",225,yh,'center')        
          // doc.text("PRECIO\rUNITARIO",240,yh,'center')        
          // doc.text("IMPORTE",255,yh);
          doc.setFontSize(6);
          doc.setFont(undefined, "normal");                            
          var y=43;   
          for (var i = 0; i < this.rows.length; i+=1) {     
            // priceuni=this.rows[i].price-(this.rows[i].price*(this.rows[i].discount/100));
            // cualquierCadena.substring(0,3)
            doc.text(this.rows[i].productname.toString().substring(0,25)+"..."+"\rSKU: "+this.rows[i].productsku.toString(),2,y);
            doc.text(this.rows[i].cantidad.toString(),33,y,'center'); 
            doc.text("$"+this.rows[i].price.toString().replace(/\d(?=(\d{3})+\.)/g, '$&,'),51,y,'right');          
            // doc.text("-"+this.rows[i].discount.toString()+"%",225,y,'center');
            // doc.text("$"+priceuni.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),240,y,'center');
            // doc.text("$"+total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),255,y);
            y = y + 7;          
          }
          y = y + 10;                  
          doc.setFontSize(7);
          doc.setFont(undefined, "normal");                            
          doc.text('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -',2,y);
          doc.text(this.currentUser.branch_site, 27,y+5,'center');          
          doc.text(folio+" | "+this.rows[0].created_at,4,y+9);
          doc.text(this.currentUser.branch_mobile+"  |  "+this.currentUser.branch_email,4,y+13);        
          // doc.text("Cliente: "+this.rows[0].customerfullname,2,y+25);


          //doc.autoPrint({variant: 'non-conform'});        
          // doc.autoPrint();
          doc.output('dataurlnewwindow', {filename: folio+".pdf"});
          //doc.save(folio+".pdf")
      },
      downloadWithCSS() {
        /** WITH CSS */
        domtoimage
        .toPng(this.$refs.content)
        .then(function(dataUrl) {
          var img = new Image();
          img.src = dataUrl;
          const doc = new jsPDF({
            orientation: "portrait",
            // unit: "pt",
            format: [900, 1400]
          });
          doc.addImage(img, "JPEG", 20, 20);
          const date = new Date();
          const filename =
            "timechart_" +
            date.getFullYear() +
            ("0" + (date.getMonth() + 1)).slice(-2) +
            ("0" + date.getDate()).slice(-2) +
            ("0" + date.getHours()).slice(-2) +
            ("0" + date.getMinutes()).slice(-2) +
            ("0" + date.getSeconds()).slice(-2) +
            ".pdf";
          doc.save(filename);
        })
        .catch(function(error) {
          console.error("oops, something went wrong!", error);
        });
      },  
      async getProduct(ProductID){
        console.log('Producto ID:'+ProductID);
        this.productid=ProductID;
        // alert(this.productid);
        this.dialogViewProduct=true;      
      },
      updateRow(row) {      
        try{        
            this.showLoading({titulo:"Accediendo información",color:"warning"})
            baseApi().post(`/detalleventa/update/${row.id}`,JSON.stringify(row)).then((response)=>{
              if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
              console.log("VENTA: "+response.data.message);
            });
          }catch(error){
            console.log(error);
          }  finally{
            this.hiddenLoading()
            // this.$swal("Genial!", "Se actualizó la información", "success");
          } 
      },      
    },
    data(){
      return{
        toggle_exclusive:'',
        enabled:false,
        dialog:false, 
        //dialog1:false, 
        dialog2:false,       
        dialog10:false,       
        dialogViewProduct:false,   
        //dialog3:false,      
        dialogNotes:false,
        notes:'', 
        dragging: false,
        //modal:[],
        sword:"",
        j:1,
        folio:"",
        customerid:0,
        customerfullname:"",
        date_salesorder:"",
        salesnotes:"",
        subtotal:0,
        desc:0,
        sfecha:new Date().toISOString().substr(0,10),
        rows: [],
        user: [],
        product: [],
        productrow:{},
        editRow:null,
        salesorder_id:'',
        price:"",
        salesorder_subtotal: 0,
        salesorder_subtotal2:0,
        salesorder_subtotal_desc:0,
        salesorder_total: 0,
        salesorder_tax: 16,      
        salesorder_iva: 0,     
        iva:0,
        theader: [
          { text: 'Acción', value: 'num' },        
          { text: 'Descripción', value: 'desc' },
          { text: 'Precio de Lista', value: 'precio_other' },
          { text: 'Cantidad', value: 'cantidad' },                
          { text: 'Total', value: 'total' },        
        ],
        acciones: [
          { title: 'Download PDF',icon:"save",metodo:"downloadPDF" }
        ],      
      }
    },
    created(){
      this.changeBreadcrumbs({
          page:"Detalle Salida "+this.sword,
          title:"Lista de detalle de la salida",
          subtitle:"primary",
          description:"En esta lista encontrará todos los detalles de la salida dadas de alta en el sistema desde el principio de los tiempos"
      });
      this.getBreadcrumps()    
    }
  }
  </script>